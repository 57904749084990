@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-Black.woff2') format('woff2'),
        url('HelveticaNeue-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-Bold.woff2') format('woff2'),
        url('HelveticaNeue-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-BlackItalic.woff2') format('woff2'),
        url('HelveticaNeue-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-BoldItalic.woff2') format('woff2'),
        url('HelveticaNeue-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-Heavy.woff2') format('woff2'),
        url('HelveticaNeue-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-Light.woff2') format('woff2'),
        url('HelveticaNeue-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-Italic.woff2') format('woff2'),
        url('HelveticaNeue-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-HeavyItalic.woff2') format('woff2'),
        url('HelveticaNeue-HeavyItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-LightItalic.woff2') format('woff2'),
        url('HelveticaNeue-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-MediumItalic.woff2') format('woff2'),
        url('HelveticaNeue-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-Roman.woff2') format('woff2'),
        url('HelveticaNeue-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-Medium.woff2') format('woff2'),
        url('HelveticaNeue-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-ThinItalic.woff2') format('woff2'),
        url('HelveticaNeue-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-Thin.woff2') format('woff2'),
        url('HelveticaNeue-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-UltraLightItalic.woff2') format('woff2'),
        url('HelveticaNeue-UltraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-UltraLight.woff2') format('woff2'),
        url('HelveticaNeue-UltraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

