@import './scss/theme.scss';
@import './scss/button.scss';
@import './scss/form.scss';
@import '../src/fonts/stylesheet.css';

html {
  height: 100%;

  body {
    height: 100%;
    font-size: 16px;
    // font-family: 'Jost', sans-serif;
    // font-family: "Roboto", sans-serif;
    font-family: 'Helvetica Neue';
    font-weight: 300;
    background: $vr-white;
  }
}

#root {
  height: 100%;
}

.App {
  height: 100%;
}

.dashboard-wrapper {
  padding-top: 86px;

  .dashboard-title-section {
    h5 {
      font-size: 24px;
      margin-bottom: 11px;
      padding-left: 15px;
    }
  }

  .cp-portal-section {
    overflow: hidden;

    .cp-page-header {
      padding-top: 16px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 16px;

      .header-title {
        color: $vr-secondary-400;
        font-size: 25px;
        font-weight: 600;
        margin: 0;
      }
    }

    .cp-portal-dashboard-wrapper {
      height: calc(100vh - 170px);
      overflow: hidden;
      padding: 0 20px;

      .cp-portal-wrapper-body {
        // height: 66vh;
        overflow: hidden;
      }
    }

    .table-wrap {
      overflow-x: auto;
      overflow-y: auto;
      // max-height: 420px;
      // max-height: calc(100vh - 20%);
      max-height: calc(100vh - 227px);
      min-height: calc(100vh - 227px);
      height: 100%;

      .table {
        min-width: 1000px !important;
        width: 100%;
        background: $vr-white;

        thead {
          &.thead-grey {
            background: $vr-base;
            border: $vr-white;
            position: sticky;
            top: -0.1px;
            z-index: 2;
          }

          th {
            border: none;
            padding: 15px 15px;
            font-size: 15px;
            color: $vr-secondary-400;
            font-weight: 600;
            background: $vr-base;
            vertical-align: middle;
            text-align: center;
          }
        }

        tbody {
          tr {
            td {
              border: none;
              padding: 12px 15px;
              border-bottom: 1px solid #f3f3f3;
              font-size: 14px;
              font-weight: 400;
              vertical-align: middle;

              // text-wrap: nowrap;
              .action-col {
                a {
                  background-color: $vr-base-400;
                  color: #64748b;
                  height: 35px;
                  width: 35px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 0.375rem;
                  font-size: 11px;
                  transition-duration: .2s;
                  cursor: pointer;

                  svg {
                    width: 1.2rem;
                  }

                  &.edit:hover {
                    color: $vr-primary-300;
                    background-color: $vr-base-200;
                  }

                  &.delete:hover {
                    color: $vr-error;
                    background-color: $vr-error-100;
                  }
                }
              }
            }

            &.table-loader {
              .custom-content-loader {
                min-height: 300px;
              }
            }
          }

          .no-data-found {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: 220px;
            width: 100%;

            figure {
              display: block;
              padding: 0;
              margin: 0;

              svg {
                width: 4rem;
                height: 4rem;
                font-size: 1rem;
                color: #e1e1e1;
                margin-bottom: 10px;
              }
            }

            label {
              font-size: 1.5rem;
              font-weight: 300;
              color: #ccc;
            }
          }
        }
      }
    }

    .MuiPagination-root {
      .MuiPagination-ul {
        justify-content: end;
      }
    }
  }
}

body {
  .offcanvas-bottom {
    max-width: 840px;
    margin: 0 auto;
    min-height: 88%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border: 0px;
    outline: 0;

    .offcanvas-header {
      justify-content: center;

      .top-line {
        max-width: 70px;
        background-color: #D9D9D9;
        height: 5px;
        border-radius: 30px;
        width: 100%;
        cursor: pointer;
        transition-duration: .2s;

        &:hover {
          background-color: #c8c7c7;
        }
      }
    }

    .offcanvas-body {
      padding: 0px 50px 34px 50px;

      .off-canvas-title {
        margin-bottom: 20px;
      }
    }
  }
}

.MuiAlert-root {
  margin-bottom: 30px;
  position: absolute;
  top: 50px;
  right: 30px;
  max-width: 400px;
  width: 100%;
  z-index: 99999;
}

@media only screen and (max-width: 480px) {
  .dashboard-wrapper {
    .cp-portal-section {
      .cp-page-header {
        padding-left: 0;
        padding-right: 0;

        .header-title {
          margin-bottom: 15px;
        }

        .header-search-input {
          margin-bottom: 15px;
        }

        .btn-cp-primary {
          max-width: 100%;
        }
      }
    }
  }

  body {
    .offcanvas-bottom {
      .offcanvas-body {
        padding: 1rem;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .dashboard-wrapper {
    .cp-portal-section {
      .cp-portal-dashboard-wrapper {
        height: calc(100vh - 260px);
      }

      .table-wrap {
        min-height: calc(100vh - 326px);
      }
    }
  }
}

.MuiStack-root {
  display: block;

  .MuiPagination-root {
    display: block;

    .MuiPagination-ul {
      li {
        .Mui-selected {
          background-color: rgb(252, 213, 107) !important;
          font-weight: 600 !important;
        }
      }
    }
  }
}

.lead-confirmation-modal {
  .modal-dialog {
    max-width: 630px;
    width: 100%;
    .modal-header {
      padding: 26px 25px;
      position: relative;
      .modal-title {
        position: absolute;
        bottom: -19px;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        font-size: 21px;
        p{
          margin: 0;
          font-size: 14px;
          color: #35a51f;
        }
      }
    }
    .modal-body {
      padding: 20px 30px;
      .user-name {
        label {

        }
        p {
          font-weight: 500;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.lead-cancel-modal {
  .modal-body {
    .icon-box {
      text-align: center;
      display: flex;
      justify-content: center;
      margin: 16px 0;
      margin-bottom: 0;
      .material-icons {
        font-style: normal;
        border: 2px solid #f15e5e;
        color: #f15e5e;
        padding: 14px;
        font-size: 2.5rem;
        width: 60px;
        display: flex;
        height: 60px;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        line-height: 0;
      }
    }
    h5 {
      text-align: center;
      margin: 10px 0 12px;
      font-size: 30px;
      font-weight: 600;
      color: #414141;
    }
    p {
      color: #585858;
      font-size: 17px;
      margin: 0 auto;
      text-align: center;
    }
  }
  .modal-footer {
    padding: 9px 0 31px;
    border: 0;
    justify-content: center;
    gap: 10px;
    .btn {
      text-decoration: none;
      transition: all 0.4s;
      line-height: normal;
      min-width: 120px;
      border: none;
      min-height: 40px;
      border-radius: 35px;
      margin: 0 5px;
      color: #fff;
      padding: 0.375rem 0.75rem;
    }
    .btn-primary {
      background: #c1c1c1;
      &:hover {
        background: #b0aeae;
      }
    }
    .btn-danger {
      background: #f15e5e;
      &:hover {
        background: #dd5555;
      }
    }
  }
}