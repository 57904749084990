.MuiFormControl-root {
    margin-bottom: 7px !important;
    p {
        &.Mui-error {
            position: absolute;
            // font-family: "Jost", sans-serif;
            // font-family: "Roboto", sans-serif;
            font-family: 'Helvetica Neue';
            left: 0;
            font-weight: 400;
            bottom: -23px;
            font-size: 13px;
            text-wrap: nowrap;
        }
    }
    &.login-form {
        .MuiFormLabel-root {
            left: 14px;
            top: 6px;
            font-size: 14px;
            &.MuiInputLabel-shrink {
                top: 0px;
                left: 2px;
                font-size: 16px;
                background: #fff;
                padding: 0 8px;
            }
        }
        .MuiInputBase-root {
            border-radius: 13px;
            // border: 1px solid #C9C9C9;
            .MuiInputBase-input {
                padding: 20px 25px;
            }
            .MuiOutlinedInput-notchedOutline {
                // margin-left: 14px;
            }
        }
    }
    &.common-input {
        .MuiFormLabel-root {
            &.MuiInputLabel-shrink {
                background: #fff;
                padding: 0px 3px;
            }
        }
        .MuiInputBase-root {
            border-radius: 10px;
            // border: 1px solid #C9C9C9;
            .MuiInputBase-input {
            }
            .MuiOutlinedInput-notchedOutline {
                // margin-left: 14px;
            }
            &.Mui-error {
                .MuiOutlinedInput-notchedOutline {
                    border-color: #cacaca;
                }
            }
        }
        .MuiFormLabel-root {
            &.Mui-error {
                color: #666666;
                &.MuiInputLabel-shrink  {
                    color: #1976d2;
                }
            }
        }
    }
}

.header-search-input {
    input {
        border-radius: 30px;
        padding-left: 20px;
    }
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear
{
    display: none;
}

.country-phone-input {
    position: relative;
    margin-bottom: 7px !important;
    .error-msg {
        color: #d32f2f;
        position: absolute;
        // font-family: "Roboto", sans-serif;
        font-family: 'Helvetica Neue';
        left: 14px;
        font-weight: 400;
        bottom: -21px;
        font-size: 13px;
        margin: 0;
    }
}


.form-group {
    position: relative;
    .common-input {
        &.form-select {
            padding: 16px 14px;
            border-radius: 10px;
            outline: 1px solid #c4c4c4;
            border: 0;
            &:focus-visible {
                outline: 2px solid #445bdf;
            }
            &:focus {
                outline: 2px solid #445bdf;
                box-shadow: none;
            }
            &:hover {
                outline: 1px solid #181818;
            }
        }
    }
    .select-label {
        background: #fff;
    padding: 0px 3px;
    color: rgb(90 89 89 / 81%);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    position: absolute;
    top: -8px;
    left: 14px;
    }
}
.react-tel-input {
    .special-label {
        background: #fff !important;
        padding: 0px 3px !important;
        color: rgba(90, 89, 89, 0.81);
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        font-size: 12px !important;
        line-height: 1.4375em;
        letter-spacing: 0.00938em;
        position: absolute !important;
        top: -8px !important;
        left: 14px !important;
    }
    .form-control {
        border-radius: 10px !important;
        width: 100% !important;
        height: 56px;
    }
}

p {
    &.error-msg {
        color: #d32f2f;
        position: absolute;
        // font-family: "Roboto", sans-serif;
        font-family: 'Helvetica Neue';
        left: 14px;
        font-weight: 400;
        bottom: -21px;
        font-size: 13px;
        margin: 0;
    }
}

.form-group {
    margin-bottom: 7px;
}

.max-budget {
    p {
        &.Mui-error {
            max-width: 350px;
            text-wrap: wrap;
            top: 55px;
            line-height: 1;
        }
    }
}

.dropdown {
    &:hover {
        .dropdown-menu {
            display: block;
        }
    }
}

.css-13cymwt-control, .css-t3ipsp-control {
    height: 57.3px;
    border-radius: 10px !important;
    border: 1px solid #c1c1c1 !important;
    &:hover {
        border: 1px solid #202020 !important; 
    }
}

.css-1nmdiq5-menu {
    display: block;
    z-index: 99999 !important;
}
.css-1u9des2-indicatorSeparator {
    display: none;
}
.css-1xc3v61-indicatorContainer {
    color: #686868 !important;
}
.css-1dimb5e-singleValue {
    color: #000;
    font-weight: 400;
}