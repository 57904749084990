@import './theme.scss';

button {
    &.btn {
        border: 0;
        &.login-btn-main {
            color: $vr-primary;
            padding: 11px 18px;
            max-width: 206px;
            background-color: $vr-white;
            border-radius: 77px;
            font-size: 20px;
            text-align: center;
            margin-top: 30px;
            border: 0;
            color: $vr-primary;
            &:hover {
                background-color: #edecec;
                color: $vr-primary;
            }
        }
        &.btn-cp-primary {
            padding: 9px 19px;
            max-width: 251px;
            // margin: 0 auto;
            width: 100%;
            border-radius: 30px;
            font-size: 16px;
            color: $vr-white;
            background-color: $vr-primary;
            &.otp-btn {
                padding: 0;
                max-width: 100px;
                margin-bottom: 7px !important;
                border-radius: 13px;
                margin-left: 10px;
            }
            
        }
        &.btn-cp-add {
            padding: 9px 19px;
            max-width: 251px;
            // margin: 0 auto;
            width: 100%;
            border-radius: 30px;
            font-size: 16px;
            color: $vr-white;
            background-color: $vr-secondary-400;
            &:hover {
                background-color: $vr-secondary-400-hover;
            }
        }
        &.btn-cp-secondary {
            padding: 9px 19px;
            max-width: 251px;
            // margin: 0 auto;
            width: 100%;
            border-radius: 30px;
            font-size: 16px;
            color: $vr-white;
            background-color: $vr-secondary;
        }
    }
}

.mw-360 {
    max-width: 360px !important;
}
